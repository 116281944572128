import { render, staticRenderFns } from "./postEdit.vue?vue&type=template&id=538ecb93&scoped=true&"
import script from "./postEdit.vue?vue&type=script&lang=js&"
export * from "./postEdit.vue?vue&type=script&lang=js&"
import style0 from "./postEdit.vue?vue&type=style&index=0&id=538ecb93&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538ecb93",
  null
  
)

export default component.exports